import Color from 'color'

const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);
const getRgb = (color) => {
    const colorRgb = color.rgb().color;
    return `${colorRgb[0]}, ${colorRgb[1]}, ${colorRgb[2]}`
}

const apply = (colors, gradientFor = []) => {
    const root = document.querySelector(":root");
    Object.keys(colors).forEach((colorTypes) => {
        const type = colors[colorTypes];
        Object.keys(type).forEach((colorName) => {
            const color = Color(type[colorName]);
            const darkenColor = color.darken(20/100);
            const lightenColor = color.lighten(30/100);
            root.style.setProperty(`--${colorName}`, color);
            root.style.setProperty(`--${colorName}-hover`, darkenColor);
            root.style.setProperty(`--${colorName}-hover-light`, lightenColor);
            root.style.setProperty(`--${colorName}-rgb`, getRgb(color));
            root.style.setProperty(`--${colorName}-hover-rgb`, getRgb(darkenColor));
            root.style.setProperty(`--${colorName}-hover-light-rgb`, getRgb(lightenColor));
        });
    });
    localStorage.setItem("theme", JSON.stringify(colors));
    if (gradientFor.length) {
        gradientFor.forEach((colorName) => {
            generateGradientPalette(colorName, 500);
        });
    }
};

const generateGradientDarken = (step, degree, colorName) => {
    const root = document.querySelector(":root");
    const maxDarken = 900;
    const minDarken = +degree;
    let darkenDegree = minDarken;
    const color = Color(getComputedStyle(document.documentElement).getPropertyValue(`--${colorName}`));
    while (darkenDegree < maxDarken) {
        darkenDegree += step;
        const amount = (maxDarken - minDarken) / 10 - Math.abs(darkenDegree - maxDarken) / 10;
        root.style.setProperty(`--${colorName}-${darkenDegree}`, color.darken(amount/100));
    }
};

const generateGradientLighten = (step, degree, colorName) => {
    const root = document.querySelector(":root");
    const color = Color(getComputedStyle(document.documentElement).getPropertyValue(`--${colorName}`));
    const maxLighten = step;
    const minLighten = +degree;
    let lightenDegree = maxLighten;
    while (lightenDegree < minLighten) {
        const amount = Math.abs(lightenDegree - minLighten) / 10;

        root.style.setProperty(`--${colorName}-${lightenDegree}`, color.lighten(amount/100));
        lightenDegree += step;
    }
};

const generateGradientPalette = (colorName, degree) => {
    const step = 100;
    generateGradientDarken(step, degree, colorName);
    generateGradientLighten(step, degree, colorName);
};

export { equals, apply };
