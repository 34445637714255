import { apply } from "./theme";

(function () {
    let timeoutId = setTimeout(() => {
        const theme = Object.values(window.settings).find(
            (setting) => setting.key_name === "Theme"
        );
        apply(theme.value, ["text"]);
        clearTimeout(timeoutId);
    }, 10);
})();
